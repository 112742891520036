import React, { Component } from 'react';
import { Button, Row, Col, Container } from 'reactstrap';
import XLSX from 'xlsx';
import "../assets/css/bootstrap.min.css";
import { GPSMapContainer } from './GPSMapContainer';
import downloadIcon from '../assets/DownloadWhite.webp';
import filterIcon from '../assets/FilterWhite.webp';

export class Table extends Component {
    static displayName = Table.name;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.exportFile = this.exportFile.bind(this);
    }

    postUploadFull(handsetID, endtime) {
        this.props.postUploadFull(handsetID, endtime);
    }

    showDetailedData(id) {
        this.props.getDataCaptureOneDetailed(id);
    }

    toggleFilterModal() {
        this.props.toggleFilter();
    }

    togglePlanModal() {
        this.props.togglePlan();
    }

    toggleDispatchModal() {
        this.props.toggleDispatch();
    }

    toggleConfigModal() {
        this.props.toggleConfig();
    }

    toggleCaptureModal() {
        this.props.toggleCapture();
    }

    toggleReviewModal() {
        this.props.toggleReview();
    }

    toggleReportModal() {
        this.props.toggleReport();
    }

    renderTable(data) {
        let contents = this.props.loading
            ?
                <div>
                    <div style={{ height: "88vh", paddingTop: "12px", paddingBottom: "12px", textAlign: "center" }} >
                        Click Filter to retrieve data
                    </div>
                </div>

            : 
                <div>
                    <div className="tableFix" style={{ "height": "48vh", "overflowY": "scroll" }}>
                        <table ref={this.myRef} aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th>Capture ID</th>
                                    <th>Date (dd/mm/yyyy)</th>
                                    <th>Time (hh:mm:ss)</th>
                                    <th>GPS Lat</th>
                                    <th>GPS Long</th>
                                    <th>Depth Measured(mm)</th>
                                    <th>Toe RL(m)</th>
                                    <th>Reflectivity(%)</th>
                                    <th>Wetness</th>
                                    <th>Points Collected</th>
                                    <th>Time to Collect(s)</th>
                                    <th>Capture Type</th>
                                    <th>Deleted</th>
                                    <th>Comment</th>
                                    <th>Detailed Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(row =>
                                    <tr style={{ textDecoration: JSON.parse(row.data).deleted ? 'line-through' : 'none'}} key={row.id}>
                                        <td>{row.id}</td>
                                        <td>{row.dateTimeLocalDateString}</td>
                                        <td>{row.dateTimeLocalTimeString}</td>
                                        <td>{JSON.parse(row.data).rovergpslat}</td>
                                        <td>{JSON.parse(row.data).rovergpslong}</td>
                                        <td>{row.depthhole_rounded}</td>
                                        <td>{JSON.parse(row.data).toerl}</td>
                                        <td>{row.reflecthole_data}</td>
                                        <td>{row.wetness_data}</td>
                                        <td>{JSON.parse(row.data).pointcount}</td>
                                        <td>{(JSON.parse(row.data).endtime - JSON.parse(row.data).starttime) / 1000}</td>
                                        <td>{row.capturetype_data}</td>
                                        <td>{row.deletedString}</td>
                                        <td>{row.commentString}</td>
                                        <td>
                                            {row.detailedDataAvailable == 0 &&
                                                <Button color="secondary" onClick={() => this.postUploadFull(JSON.parse(row.data).handsetID, JSON.parse(row.data).endtime)}>Request</Button>
                                            }
                                            {row.detailedDataAvailable == 1 &&
                                                <Button color="success" onClick={() => this.showDetailedData(row.id)}>Show</Button>
                                            }
                                            {row.detailedDataAvailable == 2 &&
                                                <div className="Pendbtn">
                                                    Pending
                                                    </div>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {this.props.showGPSMap ? <GPSMapContainer selectionModeEnabled={false} PDFref={this.props.PDFref} tilelayerenabled={true} capturedataLoaded={true} plandataLoaded={false} missingdataLoaded={false} diffdataLoaded={false} capturedata={this.props.data} /> : <div style={{ height: "400px", width: "100%" }}></div>}
                    </div>
                </div>

        return (
            <div >
                <Container fluid>
                    <Row> {/* style={{ backgroundColor:"#eee" }}> */}
                        <Col style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", padding: "5px" }} >
                            <Button onClick={() => this.toggleFilterModal()} >
                                {
                                    // <img src={filterIcon} />
                                }
                                Filter
                                </Button>
                        </Col>
                        <Col style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", padding: "5px" }} >
                            <Button onClick={() => this.toggleConfigModal()} >
                                Config
                                </Button>
                        </Col>
                        <Col style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", padding: "5px" }} >
                            <Button onClick={() => this.togglePlanModal()} >
                                Plan
                                </Button>
                        </Col>
                        <Col style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", padding: "5px" }} >
                            <Button onClick={() => this.toggleDispatchModal()} >
                                Dispatch
                            </Button>
                        </Col>
                        <Col style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", fontSize: 20 }} >
                            Dashboard
                        </Col>
                        <Col style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", padding: "5px" }} >
                            <Button onClick={() => this.toggleCaptureModal()} >
                                Capture
                                </Button>
                        </Col>
                        <Col style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", padding: "5px" }} >
                            <Button onClick={() => this.toggleReviewModal()} >
                                Review
                            </Button>
                        </Col>
                        <Col style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", padding: "5px" }} >
                            <Button onClick={() => this.toggleReportModal()} >
                                Report
                            </Button>
                        </Col>
                        <Col style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", padding: "5px" }} >                            
                            <Button onClick={this.exportFile}>
                                <img src={downloadIcon} />
                                Export to Excel
                                </Button>
                        </Col>
                    </Row>
                </Container>

                {contents}

            </div>
        );
    }

    exportFile() {
        /* convert table to worksheet */
        const ws = XLSX.utils.table_to_sheet(this.myRef.current); /*refes to DOM element*/

        /* build new workbook */
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Dipstick");

        /* write file */
        const datestring = this.props.endDate.getFullYear() + "-" + (this.props.endDate.getMonth() + 1) + "-" +this.props.endDate.getDate()  + "_" + this.props.endDate.getHours() + this.props.endDate.getMinutes();
        XLSX.writeFile(wb, "Dipstick-" + this.props.selectedhardwareID + "_" + datestring + '.xlsx');
    }

    render() {
        let contents = this.renderTable(this.props.data);

        return (
            <div>
                {contents}
            </div>
        );
    }

}
