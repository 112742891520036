import React, { Component } from 'react';
import { Tree } from 'react-arborist';
import { ListGroup, ListGroupItem} from 'reactstrap';


function contextMenu(e) {
    e.preventDefault();
    console.log("Right click", e.pageX, e.pageY)
    this.setState({showContext: true, contextX: e.pageX, contextY: e.pageY})
}

function Node({ node, style, dragHandle }) {
    var ele;
    if (node.isSelected) {
        ele = <b>{node.data.name}</b>;
    } else {
        ele = <>{ node.data.name }</>;
    }

    return (
        <div style={style} onContextMenu={contextMenu.bind(this)}>
            {ele}
        </div>
    );
}



export class TreeView extends Component {
    constructor(props) {
        super(props);

        this.state = { showContext: false, contextX: 0, contextY: 0 };
    }

    nodeSelect(node) {
        if (node.length > 0) {
            if (node[0].data != null) {
                //console.log(node[0].data.id);
                var type = node[0].data.id.split("-")[0];
                var id = node[0].data.id.split("-")[1]
                if (type == "subloc") {
                    this.props.setSelectedSubLocation(id);
                } else if (type == "seq") {
                    this.props.setSelectedSequence(id);
                } else if (type == "subseq") {
                    this.props.setSelectedSubSequence(id);
                }
                
            }
        }
    }

    render() {

        return (
            <>
            <Tree data={this.props.treeData} disableDrag={true} disableDrop={true} onSelect={this.nodeSelect.bind(this)}>
                {Node.bind(this)}
            </Tree>

            {
                    this.state.showContext && (<div style={{ position: 'absolute', width: '100px', borderradius: '5px', top: this.state.contextY + "px", left: this.state.contextX + "px" }}>
                    <ListGroup>
                        <ListGroupItem action active tag="button">
                            Click Me!!
                        </ListGroupItem>
                    </ListGroup>
                </div>)
            }
            </>
        );
    }
}