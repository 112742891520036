import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './Auth0Config';

export default class AuthService {

    constructor(props) {
        this.state = {
            //User Data
            name: "User",
            organisationName: "Company"
        };
    }

    auth0 = new auth0.WebAuth({
        domain: AUTH_CONFIG.domain,
        clientID: AUTH_CONFIG.clientID,
        redirectUri: AUTH_CONFIG.redirectUri,
        audience: AUTH_CONFIG.audience,
        responseType: 'token id_token',
        scope: 'openid https://athena.com/user_metadata https://athena.com/app_metadata'
    });

    login() {
        this.auth0.authorize();
    }

    logout() {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('id_token');
        sessionStorage.removeItem('expires_at');
        this.auth0.logout({
            returnTo: AUTH_CONFIG.logoutUri,
            clientID: AUTH_CONFIG.clientID
        });
    }

    handleAuthentication(history) {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
                history.push("/");
            } else if (err) {
                console.log(err);
            }
        });
    }

    setSession(authResult) {
        let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        sessionStorage.setItem('access_token', authResult.accessToken);
        sessionStorage.setItem('id_token', authResult.idToken);
        sessionStorage.setItem('expires_at', expiresAt);
    }

    isAuthenticated() {
        let expiresAt = JSON.parse(sessionStorage.getItem('expires_at')); //Can use localStorage if you dont want to be cleaned on browser closure
        return new Date().getTime() < expiresAt;
    }

    getAccessToken() {
        const accessToken = sessionStorage.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found');
        }
        return accessToken;
    }

    getUserInfo(accessToken, callback) {
        this.auth0.client.userInfo(accessToken, function (err, user) {
            // Now you have the user's information
            callback(user["https://athena.com/app_metadata"]);
        });
    }

}